var axios = require("axios").default;
const baseUrl = `https://api.ebsr.in/`;
const localData = JSON.parse(localStorage.getItem('token'))
const token = localData.token
export default class Tissue {
    async TissueDetail_od(data) {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}tissueDetails/fetch_details_OD/${data}`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async TissueDetail_os(data) {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}tissueDetails/fetch_details_OS/${data}`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async AllList() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}tissueDetails/all_tissue_details`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async AllListDisposition() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}TissueDisposition/view_tissue_disposition`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async TissueDisposition(data) {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}TissueDisposition/fetch_tissue_disposition/${data}`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async TissueOffered() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}tissueOffer/get_tissue_offer`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async SurgeonList() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}users/view/hospital_surgeon`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async TissueList() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}tissueList/get_tissue_list`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async SingleTissueData(data) {
        try {
            const response = await axios.post(`${baseUrl}tissueOffer/get_tissue_offer_byId`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }
    }
    async updateTissue(data) {
        try {
            const response = await axios.post(`${baseUrl}tissueOffer/edit_tissue_offer`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })

            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }

    };
}