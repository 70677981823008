<template>
  <v-dialog v-model="show" persistent :retain-focus="false" max-width="1000">
    <v-card>
      <v-card-title class="justify-center">
        <span class="text-h5"><strong> Edit Tissue Offered</strong></span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field v-model="recoveryDateTime" label="Tissue Recovery Date" required readonly>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field v-model="tissueOfferedDateTime" label="Tissue Offered Date & Time" required
                readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field v-model="item.tissue_id" label="Tissue ID" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field v-model="item.death_to_preservation_time" label="D to P " readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field v-model="item.age" label="Age" required @keypress="onlyNumber" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field v-model="item.approved_usages" label="Approved Usages" readonly></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field v-model="item.cell_count" label="Cell count" readonly></v-text-field>
            </v-col>

            <v-col cols="12" sm="4">
              <v-autocomplete v-model="item.tissue_category" v-if="newItem.tissue_outcome_status == false"
                label="Tissue Category*" required :rules="nameee" :items="TissueCategory"></v-autocomplete>

              <v-autocomplete v-model="item.tissue_category" v-if="newItem.tissue_outcome_status == true"
                label="Tissue Category*" readonly required :rules="nameee" :items="TissueCategory"></v-autocomplete>

            </v-col>

            <v-col cols="12" sm="4">
              <v-autocomplete v-model="item.hospital_category"
                v-if="newItem.tissue_outcome == 'Distributed' || newItem.tissue_outcome == 'Research' || newItem.tissue_outcome == 'Discard'"
                readonly label="Hospital Category*" required :rules="nameee"
                @change="HospitalCategoryFun(item.hospital_category)" :items="HospitalCategory"></v-autocomplete>
              <v-autocomplete v-model="item.hospital_category"
                v-if="newItem.tissue_outcome == 'Response Awaiting' || newItem.tissue_outcome == 'Accepted' || newItem.tissue_outcome == 'Withdrawal' || newItem.tissue_outcome == 'Returned'"
                label="Hospital Category*" required :rules="nameee"
                @change="HospitalCategoryFun(item.hospital_category)" :items="HospitalCategory"></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="4" class="d-none">
              <v-text-field v-model="HospitalCategoryFunCum" label="Hospital Category"></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-autocomplete v-model="item.hospital_name"
                v-if="newItem.tissue_outcome == 'Distributed' || newItem.tissue_outcome == 'Research' || newItem.tissue_outcome == 'Discard'"
                readonly @change="HospitalNameFun(item.hospital_name)" label="Hospital Name*" :rules="nameee"
                :items="hospitalName">
              </v-autocomplete>
              <v-autocomplete v-model="item.hospital_name"
                v-if="newItem.tissue_outcome == 'Response Awaiting' || newItem.tissue_outcome == 'Accepted' || newItem.tissue_outcome == 'Withdrawal' || newItem.tissue_outcome == 'Returned'"
                @change="HospitalNameFun(item.hospital_name)" label="Hospital Name*" :rules="nameee"
                :items="hospitalName">
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="4" class="d-none">
              <v-text-field v-model="HospitalNameFunCum" label="Hospital Name"></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-autocomplete v-model="item.surgeon_name"
                v-if="newItem.tissue_outcome == 'Distributed' || newItem.tissue_outcome == 'Research' || newItem.tissue_outcome == 'Discard'"
                readonly label="Surgeon Name*" :items="surgeonName" :rules="nameee"></v-autocomplete>
              <v-autocomplete v-model="item.surgeon_name"
                v-if="newItem.tissue_outcome == 'Response Awaiting' || newItem.tissue_outcome == 'Accepted' || newItem.tissue_outcome == 'Withdrawal' || newItem.tissue_outcome == 'Returned'"
                label="Surgeon Name*" :items="surgeonName" :rules="nameee"></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="4" class="d-none">
              <v-text-field v-model="item.progress_count" label="Progress Count"></v-text-field>
            </v-col>

            <v-col cols="12" sm="4">
              <v-autocomplete
                v-if="item.recipientFormFilled === 0 && (newItem.tissue_outcome == 'Response Awaiting' && newItem.for_research == 0)"
                v-model="item.tissue_outcome" :items="productsAwaiting" :item-disabled="shouldDisableItem"
                @change="newValue()" :rules="nameee" label="Tissue Outcome*">
                <!-- @change="method()" -->
              </v-autocomplete>
              <v-autocomplete
                v-if="item.recipientFormFilled === 0 && (newItem.tissue_outcome == 'Response Awaiting' && newItem.for_research == 1)"
                v-model="item.tissue_outcome" :items="productsResearch" :item-disabled="shouldDisableItem"
                @change="newValue()" :rules="nameee" label="Tissue Outcome*">
                <!-- @change="method()" -->
              </v-autocomplete>
              <v-autocomplete
                v-if="item.recipientFormFilled === 0 && (newItem.tissue_outcome == 'Accepted' && newItem.for_research == 0)"
                v-model="item.tissue_outcome" :items="productsAccepted" :item-disabled="shouldDisableItem"
                @change="newValue()" :rules="nameee" label="Tissue Outcome*">
                <!-- @change="method()" -->
              </v-autocomplete>
              <v-autocomplete
                v-if="item.recipientFormFilled === 0 && (newItem.tissue_outcome == 'Accepted' && newItem.for_research == 1)"
                v-model="item.tissue_outcome" :items="productsResearch" :item-disabled="shouldDisableItem"
                @change="newValue()" :rules="nameee" label="Tissue Outcome*">
                <!-- @change="method()" -->
              </v-autocomplete>
              <v-autocomplete
                v-if="item.recipientFormFilled === 0 && (newItem.tissue_outcome == 'Distributed' && newItem.bill_status != 0 && newItem.for_research == 0)"
                v-model="item.tissue_outcome" :items="productsDistributed" :item-disabled="shouldDisableItem"
                @change="newValue()" :rules="nameee" label="Tissue Outcome*">
                <!-- @change="method()" -->
              </v-autocomplete>
              <v-autocomplete
                v-if="item.recipientFormFilled === 0 && (newItem.tissue_outcome == 'Distributed' && newItem.bill_status != 0 && newItem.for_research == 1)"
                v-model="item.tissue_outcome" :items="productsResearch" :item-disabled="shouldDisableItem"
                @change="newValue()" :rules="nameee" label="Tissue Outcome*">
                <!-- @change="method()" -->
              </v-autocomplete>
              <v-autocomplete
                v-if="item.recipientFormFilled === 0 && (newItem.tissue_outcome == 'Distributed' && newItem.bill_status == 0 && newItem.for_research == 0)"
                v-model="item.tissue_outcome" :items="productsResearchEnd" :item-disabled="shouldDisableItem"
                @change="newValue()" :rules="nameee" label="Tissue Outcome*">
                <!-- @change="method()" -->
              </v-autocomplete>
              <v-autocomplete
                v-if="item.recipientFormFilled === 0 && (newItem.tissue_outcome == 'Distributed' && newItem.bill_status == 0 && newItem.for_research == 1)"
                v-model="item.tissue_outcome" :items="productsResearch" :item-disabled="shouldDisableItem"
                @change="newValue()" :rules="nameee" label="Tissue Outcome*">
                <!-- @change="method()" -->
              </v-autocomplete>
              <v-autocomplete
                v-if="item.recipientFormFilled === 0 && (newItem.tissue_outcome == 'Research' && newItem.for_research == 1)"
                v-model="item.tissue_outcome" :items="productsResearch" :item-disabled="shouldDisableItem"
                @change="newValue()" :rules="nameee" label="Tissue Outcome*">
                <!-- @change="method()" -->
              </v-autocomplete>
              <v-autocomplete
                v-if="item.recipientFormFilled === 0 && (newItem.tissue_outcome == 'Research' && newItem.for_research == 0)"
                v-model="item.tissue_outcome" :items="productsResearchEnd" :item-disabled="shouldDisableItem"
                @change="newValue()" :rules="nameee" label="Tissue Outcome*">
                <!-- @change="method()" -->
              </v-autocomplete>
              <v-autocomplete
                v-if="item.recipientFormFilled === 0 && (newItem.tissue_outcome == 'Withdrawal' || newItem.tissue_outcome == 'Returned') && newItem.for_research == 0"
                v-model="item.tissue_outcome" :items="productsWithdrawal" :item-disabled="shouldDisableItem"
                @change="newValue()" :rules="nameee" label="Tissue Outcome*">
                <!-- @change="method()" -->
              </v-autocomplete>
              <v-autocomplete
                v-if="item.recipientFormFilled === 0 && (newItem.tissue_outcome == 'Withdrawal' || newItem.tissue_outcome == 'Returned') && newItem.for_research == 1"
                v-model="item.tissue_outcome" :items="productsResearch" :item-disabled="shouldDisableItem"
                @change="newValue()" :rules="nameee" label="Tissue Outcome*">
                <!-- @change="method()" -->
              </v-autocomplete>
              <v-autocomplete v-if="item.recipientFormFilled === 1" v-model="item.tissue_outcome" :items="productss1"
                @change="newValue()" :rules="nameee" label="Tissue Outcome*">
                <!-- @change="method()" -->
              </v-autocomplete>
              <v-autocomplete
                v-if="item.recipientFormFilled === 0 && (newItem.tissue_outcome == 'Discard' && newItem.for_research == 0)"
                v-model="item.tissue_outcome" :items="productsDiscard" :item-disabled="shouldDisableItem"
                @change="newValue()" :rules="nameee" label="Tissue Outcome*">
                <!-- @change="method()" -->
              </v-autocomplete>
              <v-autocomplete
                v-if="item.recipientFormFilled === 0 && (newItem.tissue_outcome == 'Discard' && newItem.for_research == 1)"
                v-model="item.tissue_outcome" :items="productsResearch" :item-disabled="shouldDisableItem"
                @change="newValue()" :rules="nameee" label="Tissue Outcome*">
                <!-- @change="method()" -->
              </v-autocomplete>
              <p v-if="item.recipientFormFilled === 0 && newItem.for_research === 1" class="text--darken-2"
                style="color: red;">
                This tissue only use for Research or training purpose
              </p>
            </v-col>

            <v-col cols="12" sm="4">
              <v-text-field v-model="item.tissue_outcome_remark" label="Tissue Outcome Remark"
                v-if="item.tissue_outcome == 'Withdrawal' || item.tissue_outcome == 'Returned' || item.tissue_outcome == 'Discard'">
              </v-text-field>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" text @click="closedata()"> Cancel </v-btn>
        <v-btn color="primary" @click="same(item)" :disabled="!formIsValid"> Save </v-btn>
      </v-card-actions>

      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>


      <v-dialog v-model="showModal" max-width="550">
        <v-card>
          <v-card-title class="text-h5"> Confirmation </v-card-title>
          <v-card-text> Are you sure you want to accept this tissue for Distribution? </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelDonorModel()"> Cancel </v-btn>
            <v-btn color="primary" @click="confirmDonorModel(e)"> Confirm </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>

import Surgeonservice from '@/service/Tissue.service'
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
import moment from 'moment'

export default {

  data() {
    return {
      nameee: [v => !!v,],
      progress_count: '',
      tissue_outcome: '',
      newitem: '',
      productss: ['Response Awaiting', 'Accepted', 'Distributed', 'Withdrawal', 'Returned', 'Research', 'Discard'],
      productss1: ['Response Awaiting', 'Accepted', 'Distributed'],
      productsAwaiting: [
        { text: 'Response Awaiting', disabled: true },
        { text: 'Accepted', disabled: false },
        { text: 'Distributed', disabled: true },
        { text: 'Withdrawal', disabled: true },
        { text: 'Returned', disabled: true },
        { text: 'Research', disabled: false },
        { text: 'Discard', disabled: false }
      ],
      productsAccepted: [
        { text: 'Response Awaiting', disabled: true },
        { text: 'Accepted', disabled: true },
        { text: 'Distributed', disabled: false },
        { text: 'Withdrawal', disabled: true },
        { text: 'Returned', disabled: true },
        { text: 'Research', disabled: true },
        { text: 'Discard', disabled: true }
      ],
      productsDistributed: [
        { text: 'Response Awaiting', disabled: true },
        { text: 'Accepted', disabled: true },
        { text: 'Distributed', disabled: true },
        { text: 'Withdrawal', disabled: false },
        { text: 'Returned', disabled: false },
        { text: 'Research', disabled: true },
        { text: 'Discard', disabled: true }
      ],
      productsResearch: [
        { text: 'Response Awaiting', disabled: true },
        { text: 'Accepted', disabled: true },
        { text: 'Distributed', disabled: true },
        { text: 'Withdrawal', disabled: true },
        { text: 'Returned', disabled: true },
        { text: 'Research', disabled: false },
        { text: 'Discard', disabled: true }
      ],
      productsResearchEnd: [
        { text: 'Response Awaiting', disabled: true },
        { text: 'Accepted', disabled: true },
        { text: 'Distributed', disabled: true },
        { text: 'Withdrawal', disabled: true },
        { text: 'Returned', disabled: true },
        { text: 'Research', disabled: true },
        { text: 'Discard', disabled: true }
      ],
      productsDiscard: [
        { text: 'Response Awaiting', disabled: true },
        { text: 'Accepted', disabled: true },
        { text: 'Distributed', disabled: true },
        { text: 'Withdrawal', disabled: true },
        { text: 'Returned', disabled: true },
        { text: 'Research', disabled: true },
        { text: 'Discard', disabled: true }
      ],
      productsWithdrawal: [
        { text: 'Response Awaiting', disabled: true },
        { text: 'Accepted', disabled: true },
        { text: 'Distributed', disabled: false },
        { text: 'Withdrawal', disabled: true },
        { text: 'Returned', disabled: true },
        { text: 'Research', disabled: false },
        { text: 'Discard', disabled: false }
      ],
      hospitalData: [],
      hospitalNameAccCat: '',
      HospitalCategory: [],
      hospitalName: [this.item.hospital_name],
      surgeonName: [this.item.surgeon_name],
      TissueCategory: ['Therapeutic', 'Op.PK'],
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      showModal: false,
      checkOutcomeRemark: false,
      TissueCategoryData: this.item.tissue_category,
      tissueOutcomeData: this.item.tissue_outcome,
    }
  },

  computed: {
    formIsValid() {
      return (
        (this.item.tissue_category || this.TissueCategoryData) &&
        this.item.hospital_name &&
        this.item.surgeon_name &&
        this.item.hospital_category &&
        this.item.tissue_outcome
      )
    },

    recoveryDateTime: {
      get() {
        return this.item.recovery_date_time ? moment.utc(this.item.recovery_date_time).format('DD-MM-YYYY HH:mm') : ''
      },
      // set(value) {
      //   this.$set(this.item, 'recovery_date_time', moment(value).format('YYYY-MM-DD HH:mm'))
      // },
    },

    tissueOfferedDateTime: {
      get() {
        return this.item.tissue_offered_date_time ? moment.utc(this.item.tissue_offered_date_time).format('DD-MM-YYYY HH:mm') : ''
      },
      // set(value) {
      //   this.$set(this.item, 'recovery_date_time', moment(value).format('YYYY-MM-DD HH:mm'))
      // },
    },

    HOTAvalidity: {
      get() {
        return this.item.HOTA_validity ? moment(this.item.HOTA_validity).format('DD-MM-YYYY HH:mm:ss') : ''
      },
      set(value) {
        this.$set(this.item, 'HOTA_validity', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss'))
      },
    },

    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
          this.$emit('getHospitalList')
        }
      },
    },

    HospitalCategoryFunCum: {
      get() {
        var type = this.item.hospital_category
        this.hospitalName = ''
        this.surgeonName = ''
        var hospitalNameCategory = this.hospitalData?.filter(e => type?.includes(e.hospital_category))
        this.hospitalNameAccCat = hospitalNameCategory
        var hospitalNameData = []
        for (var i = 0; i < hospitalNameCategory.length; i++) {
          hospitalNameData.push(hospitalNameCategory[i].hospital_name)
        }
        this.hospitalName = hospitalNameData
      }
    },

    HospitalNameFunCum: {
      get() {
        var type = this.item.hospital_name
        this.surgeonName = ''
        var surgonNameData = ''
        var hospitalNametoSurgon = this.hospitalNameAccCat.filter(function (el) {
          if (el.hospital_name == type) {
            surgonNameData = el.surgeon_name
          }
        });
        var finalSurgonName = ''
        if (surgonNameData?.includes(',')) {
          finalSurgonName = surgonNameData.split(",")
        } else {
          finalSurgonName = surgonNameData
        }
        this.surgeonName = finalSurgonName
      }
    }

  },

  watch: {
    options: {
      handler() {
        this.getSurgeonList()
      },
      deep: true,
    },
  },

  async mounted() {
    this.getSurgeonList()
  },

  props: ['visible', 'item', 'newItem', 'allItem', 'hospitalCat'],

  methods: {

    shouldDisableItem(item) {
      return item.disabled;
    },

    confirmDonorModel() {
      this.showModal = false;
    },

    cancelDonorModel() {
      this.closedata()
    },

    showDonorModel() {
      this.showModal = true
    },

    closedata() {
      this.show = false
    },

    newValue() {
      this.item.tissue_outcome_remark = "";
      if (this.item.tissue_outcome == "Response Awaiting") {
        this.progress_count = "1"
        this.checkOutcomeRemark = false;

      } else if (this.item.tissue_outcome == "Accepted") {
        this.showDonorModel();
        this.progress_count = "2"
        this.checkOutcomeRemark = false;
        return

      } else if (this.item.tissue_outcome == "Distributed") {
        this.progress_count = "3"
        this.checkOutcomeRemark = false;
      } else if (this.item.tissue_outcome == "Withdrawal") {
        this.progress_count = "4"
        this.checkOutcomeRemark = true;
      } else if (this.item.tissue_outcome == "Returned") {
        this.progress_count = "5"
        this.checkOutcomeRemark = true;
      } else if (this.item.tissue_outcome == "Research") {
        this.progress_count = "6"
        this.checkOutcomeRemark = false;
      } else if (this.item.tissue_outcome == "Discard") {
        this.progress_count = "7"
        this.checkOutcomeRemark = true;
      }
    },

    same(item) {
      if ((item.tissue_outcome == "Distributed" && this.newItem.tissue_outcome == "Distributed") && item.bill_status == 2) {
        this.snackbarText = 'Tissue already Distributed. Please select the correct "outcome" status before saving.';
        this.snackbar = true;
        return;
      }
      if ((item.tissue_outcome == "Withdrawal" && this.newItem.tissue_outcome == "Withdrawal") &&
        (item.hospital_category == this.newItem.hospital_category) && (item.hospital_name == this.newItem.hospital_name) &&
        (item.surgeon_name == this.newItem.surgeon_name)) {
        this.snackbarText = 'Tissue is already Withdrawn. Please select the desired "outcome" status before saving.';
        this.snackbar = true;
        return;
      }
      if (item.tissue_outcome == "Withdrawal" && this.newItem.tissue_outcome == "Withdrawal" && item.hospital_category != this.newItem.hospital_category) {
        if (item.hospital_name == this.newItem.hospital_name) {
          this.snackbarText = 'Please select hospital name to proceed with your changes.';
          this.snackbar = true;
          return;
        } else if (item.surgeon_name == this.newItem.surgeon_name) {
          this.snackbarText = 'Please select surgeon name to proceed with your changes.';
          this.snackbar = true;
          return;
        } else {
          this.snackbarText = 'Tissue is already Withdrawn. Please select a different "outcome" status from "Withdrawal" to proceed with your changes.';
          this.snackbar = true;
          return;
        }
      }
      if (item.tissue_outcome == "Withdrawal" && this.newItem.tissue_outcome == "Withdrawal" && item.hospital_category == this.newItem.hospital_category) {
        if (item.hospital_name == this.newItem.hospital_name && item.surgeon_name == this.newItem.surgeon_name) {
          this.snackbarText = 'Please select hospital name to proceed with your changes.';
          this.snackbar = true;
          return;
        } else if (item.surgeon_name == this.newItem.surgeon_name && item.hospital_name != this.newItem.hospital_name) {
          this.snackbarText = 'Please select surgeon name to proceed with your changes.';
          this.snackbar = true;
          return;
        } else {
          this.snackbarText = 'Tissue is already Withdrawn. Please select a different "outcome" status from "Withdrawal" to proceed with your changes.';
          this.snackbar = true;
          return;
        }
      }
      if ((item.tissue_outcome == "Returned" && this.newItem.tissue_outcome == "Returned") &&
        (item.hospital_category == this.newItem.hospital_category) && (item.hospital_name == this.newItem.hospital_name) &&
        (item.surgeon_name == this.newItem.surgeon_name)) {
        this.snackbarText = 'Tissue is already Returned. Please select the desired "outcome" status before saving.';
        this.snackbar = true;
        return;
      }
      if (item.tissue_outcome == "Returned" && this.newItem.tissue_outcome == "Returned" && item.hospital_category != this.newItem.hospital_category) {
        if (item.hospital_name == this.newItem.hospital_name) {
          this.snackbarText = 'Please select hospital name to proceed with your changes.';
          this.snackbar = true;
          return;
        } else if (item.surgeon_name == this.newItem.surgeon_name) {
          this.snackbarText = 'Please select surgeon name to proceed with your changes.';
          this.snackbar = true;
          return;
        } else {
          this.snackbarText = `Tissue is already Returned from "${this.newItem.hospital_name}". Please select an appropriate "Tissue Outcome" status to be allowed to save your changes.`;
          this.snackbar = true;
          return;
        }
      }
      if (item.tissue_outcome == "Returned" && this.newItem.tissue_outcome == "Returned" && item.hospital_category == this.newItem.hospital_category) {
        if (item.hospital_name == this.newItem.hospital_name && item.surgeon_name == this.newItem.surgeon_name) {
          this.snackbarText = 'Please select hospital name to proceed with your changes.';
          this.snackbar = true;
          return;
        } else if (item.surgeon_name == this.newItem.surgeon_name && item.hospital_name != this.newItem.hospital_name) {
          this.snackbarText = 'Please select surgeon name to proceed with your changes.';
          this.snackbar = true;
          return;
        } else {
          this.snackbarText = `Tissue is already Returned from "${this.newItem.hospital_name}". Please select an appropriate "Tissue Outcome" status to be allowed to save your changes.`;
          this.snackbar = true;
          return;
        }
      }

      if (item.tissue_outcome == "Research" && this.newItem.tissue_outcome == "Research") {
        this.snackbarText = "Tissue has already been billed for Research, it's outcome can not be changed.";
        this.snackbar = true;
        return;
      }
      if (item.tissue_outcome == "Research") {
        this.editTissue()
        return;
      }
      if (item.tissue_outcome == "Discard" && this.newItem.tissue_outcome == "Discard") {
        this.snackbarText = `This tissue has already been "Discarded" hence it's outcome can not be changed.`;
        this.snackbar = true;
        return;
      }

      if (this.checkOutcomeRemark) {
        if (this.item.tissue_outcome_remark == "" || this.item.tissue_outcome_remark == undefined) {
          this.snackbarText = "Please Input Remark",
            this.snackbar = true
          return;
        }
      }
      this.editTissue()
      if (this.item.tissue_outcome == 'Distributed' || this.item.tissue_outcome == 'Research') {
        this.$router.push({ name: 'BillOfLading', params: { donor_id: item.donor_id, tissue_id: item.tissue_id } });
      }
      else if (this.item.tissue_outcome == 'Withdrawal' || this.item.tissue_outcome == 'Returned') {
        this.$router.push({ name: 'BillOfLadingViewitem', params: { tissue_id: item.tissue_id } });
      }
    },

    async getSurgeonList() {
      const service = new Surgeonservice()
      let response = await service.SurgeonList()
      let responseData = response.data
      this.hospitalData = response.data
      var hospitalCategoryData = []
      for (var i = 0; i < responseData.length; i++) {
        if (responseData[i].hospital_category != "") {
          hospitalCategoryData.push(responseData[i].hospital_category)
        }
      }
      this.HospitalCategory = hospitalCategoryData
    },

    HospitalCategoryFun(type) {
      this.hospitalName = ''
      this.surgeonName = ''
      var hospitalNameCategory = this.hospitalData?.filter(e => type?.includes(e.hospital_category))
      this.hospitalNameAccCat = hospitalNameCategory
      var hospitalNameData = []
      for (var i = 0; i < hospitalNameCategory.length; i++) {
        hospitalNameData.push(hospitalNameCategory[i].hospital_name)
      }
      this.hospitalName = hospitalNameData
    },

    HospitalNameFun(type) {
      this.surgeonName = ''
      var surgonNameData = ''
      var hospitalNametoSurgon = this.hospitalNameAccCat.filter(function (el) {
        if (el.hospital_name == type) {
          surgonNameData = el.surgeon_name
        }
      });
      var finalSurgonName = ''
      if (surgonNameData?.includes(',')) {
        finalSurgonName = surgonNameData.split(",")
      } else {
        finalSurgonName = surgonNameData
      }
      this.surgeonName = finalSurgonName
    },


    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length == 2) {
        $event.preventDefault();
      }
    },


    async editTissue() {
      if (this.progress_count == '' || this.progress_count === null || this.progress_count === undefined) {
        this.progress_count = this.item.progress_count
      }
      try {
        if (this.item.tissue_category != '') {
          var data = {
            donor_id: this.item.donor_id,
            od_and_os: this.item.od_and_os,
            tissue_id: this.item.tissue_id,
            tissue_category: this.item.tissue_category,
            hospital_name: this.item.hospital_name,
            surgeon_name: this.item.surgeon_name,
            hospital_category: this.item.hospital_category,
            tissue_outcome: this.item.tissue_outcome,
            progress_count: this.progress_count,
            tissue_outcome_remark: this.item.tissue_outcome_remark
          }
        } else {
          var data = {
            donor_id: this.item.donor_id,
            od_and_os: this.item.od_and_os,
            tissue_id: this.item.tissue_id,
            tissue_category: this.TissueCategoryData,
            hospital_name: this.item.hospital_name,
            surgeon_name: this.item.surgeon_name,
            hospital_category: this.item.hospital_category,
            tissue_outcome: this.item.tissue_outcome,
            progress_count: this.progress_count,
            tissue_outcome_remark: this.item.tissue_outcome_remark
          }
        }


        const service = new Surgeonservice()
        const response = await service.updateTissue(data)
        if (response.status == 200) {
          this.snackbarText = response.message,
            // this.method()
            this.snackbar = true
          setTimeout(() => {
            this.show = false
          }, 1500)
        } else {
          this.snackbarText = response.message,
            this.snackbar = true
        }
      } catch (e) {
        console.log(e)
      }
    }

  }
}

</script>