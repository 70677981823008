var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"justify-space-between mb-0 py-7"},[_c('span',{staticClass:"text-h5"},[_c('strong',[_vm._v("Tissue Offered")])])]),_c('v-row',{staticClass:"ml-2 mr-2 mb-3"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Tissue ID","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filter.tissueid),callback:function ($$v) {_vm.$set(_vm.filter, "tissueid", $$v)},expression:"filter.tissueid"}})],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Hospital Name","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filter.hospital_name),callback:function ($$v) {_vm.$set(_vm.filter, "hospital_name", $$v)},expression:"filter.hospital_name"}})],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Surgeon Name","outlined":"","dense":"","hide-details":""},model:{value:(_vm.filter.surgeon_name),callback:function ($$v) {_vm.$set(_vm.filter, "surgeon_name", $$v)},expression:"filter.surgeon_name"}})],1),_c('v-col',{staticClass:"pl-0 text-right",attrs:{"cols":"12","md":"3"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.TissueOfferedSearch()}}},[_vm._v(" Search")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.TissueView()}}},[_vm._v("Reset")])],1)],1),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.TissueList,"items-per-page":10,"fixed-header":"","height":"400","item-key":"tissue_id","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.recovery_date_time",fn:function(ref){
var item = ref.item;
return [_c('pre',[_vm._v(_vm._s(_vm.moment.utc(item.recovery_date_time).format('DD-MM-YYYY HH:mm')))])]}},{key:"item.tissue_offered_date_time",fn:function(ref){
var item = ref.item;
return [_c('pre',[_vm._v(_vm._s(item.tissue_offered_date_time == "" || item.tissue_offered_date_time == "Invalid date" ? "" : _vm.moment.utc(item.tissue_offered_date_time).format('DD-MM-YYYY HH:mm')))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.pdf_path != '' || item.tissue_outcome != 'Response Awaiting')?_c('v-btn',{attrs:{"color":"primary","href":("https://api.ebsr.in/" + (item.pdf_path)),"target":"_blank"}},[_vm._v(" Print ")]):_vm._e()]}},{key:"item.operate",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.editform(item)}}},[_vm._v(" Offer ")]),_c('EditOD',{attrs:{"visible":_vm.editAdd,"hospitalCat":item,"item":_vm.editItem,"newItem":_vm.newEditItem,"allItem":_vm.allItem},on:{"close":function($event){_vm.editAdd = false}}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('p',{staticClass:"justify-center d-flex"},[_c('strong',[_vm._v(" Progress Bar ")])]),_c('v-stepper',{staticClass:"ml-n9",attrs:{"alt-labels":""},model:{value:(item.progress_count),callback:function ($$v) {_vm.$set(item, "progress_count", $$v)},expression:"item.progress_count"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":item.progress_count > 1,"step":"1"}},[_c('div',{staticClass:"text-center"},[_vm._v("Response Awaiting")])]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":item.progress_count > 2 && item.tissue_outcome != 'Research',"step":"2"}},[_c('div',{staticClass:"text-center"},[_vm._v("Accepted")])]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":item.progress_count > 3 && item.tissue_outcome != 'Research',"step":"3"}},[_c('div',{staticClass:"text-center"},[_vm._v("Distributed")])]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":item.progress_count > 4 && item.tissue_outcome != 'Research',"step":"4"}},[_c('div',{staticClass:"text-center"},[_vm._v("Withdrawal")])]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":item.progress_count > 5 && item.tissue_outcome != 'Research',"step":"5"}},[_c('div',{staticClass:"text-center"},[_vm._v("Returned")])]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":item.progress_count > 6,"step":"6"}},[_c('div',{staticClass:"text-center"},[_vm._v("Research")])]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"7"}},[_c('div',{staticClass:"text-center"},[_vm._v("Discard")])])],1)],1)],1)]}}],null,true)}),_c('v-snackbar',{attrs:{"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }